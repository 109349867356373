@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1e1542;
}

@media (min-width: 90rem) {
  .container {
    max-width: 90rem;
  }
}

.main-body {
  min-height: calc(100vh);
}

@media (min-width: 48rem) {
  .main-body {
    min-height: calc(100vh);
  }
}

.main-button {
  padding: 20px 52px 20px 52px;
  background-color: #69816b;
  color: #fff;
  font-weight: 900;
  font-size: 1.5rem;
  box-shadow: 0px 1px 3px 1px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
}

.report-btn {
  padding: 1.25rem 3.25rem 1.25rem 3.25rem;
  box-shadow: 0px 1px 3px 1px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;
}

.modal-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* For Safari */
  background-color: rgba(255, 255, 255, 0.3);
}

#iIcon {
  cursor: pointer;
  display: inline;
  padding: 0.3rem;
  width: 1.7rem;
}

.present-image {
  width: 12.5rem;
  height: 10.53875rem;
}

@media (min-width: 768px) {
  .report-btn {
    padding: 2rem 4.25rem 2rem 4.25rem;
  }

  .main-button {
    padding: 1.9rem 4rem 1.9rem 4rem;
  }

  .present-image {
    width: 23.7075rem;
    height: 20.02rem;
  }
}

.main-bg {
  background-image: url("../img/images/bg.png");
  background-position: center 7rem;
  background-repeat: repeat-y;
  background-size: 84% auto;
}

.card-active {
  background-color: #fff;
}

.card-active .card-btn {
  background-color: #1e1542;
  color: #fff;
}

.card-active .card-step {
  color: #000;
}

.card-active .card-title {
  color: #000;
}

.card-inactive {
  background-color: #f1eff9;
  color: #584a8c;
}

.card-inactive .card-btn {
  border: 1px solid #584a8c;
}

@media (min-width: 768px) {
  .card-active {
    border-top: 0.625rem;
    border-color: #5c9673;
    border-style: solid;
  }

  .card-active .card-number {
    color: #5c9673;
  }

  .card-active .card-title {
    color: #5c9673;
  }

  .card-inactive {
    border: 1px;
    border-color: #1e1542;
    border-style: solid;
    background-color: transparent;
  }
}

.auth-message {
  background: #8ae0ac;
  padding: 10px;
  border-radius: 10px;
  color: white;
  margin-bottom: 0.5rem;
  text-align: center;
}

.step-button {
  border-radius: 0.3125rem;
}

.inactive.step-button {
  background-color: #fff;
}

.active.step-button {
  background-color: #1e1542;
  color: #fff;
}

@media (min-width: 768px) {
  .inactive.step-button {
    background-color: #f9f8ff;
  }
}

.lesson-button:hover,
.lesson-button:active {
  opacity: 0.9;
}
