.mh-100vh {
  min-height: 100vh;
}

.mh-152 {
  min-height: 38rem;
}

.ml-0-625 {
  margin-left: 0.625rem;
}

.mb-15 {
  margin-bottom: 3.75rem;
}

.mb-1-625 {
  margin-bottom: 1.625rem;
}

.mb-2-875 {
  margin-bottom: 2.875rem;
}

.mb-3-125 {
  margin-bottom: 3.125rem;
}

.mt-4-375 {
  margin-top: 4.375rem;
}

.max-w-30 {
  max-width: 30rem;
}

.max-w-50 {
  max-width: 50rem;
}

.max-w-75 {
  max-width: 75rem;
}

.h-2-5625 {
  height: 2.5625rem;
}

.h-3-5 {
  height: 3.5rem;
}

.h-3-125 {
  height: 3.125rem;
}

.h-5-1825 {
  height: 5.1825rem;
}

.w-10-625 {
  width: 10.625rem;
}

.w-1-125 {
  width: 1.125rem;
}

.w-1-875 {
  width: 1.875;
}

.w-2-2 {
  width: 2.2rem;
}

.w-11 {
  width: 11rem;
}

.w-15-5 {
  width: 15.5rem;
}

.w-16-25 {
  width: 16.25rem;
}

.w-100p {
  width: 100%;
}

.w-9-375 {
  width: 9.375rem;
}

.mw-2-625 {
  max-width: 2.625rem;
}

.h-100p {
  height: 100%;
}

.wh-1-25 {
  width: 1.25rem;
  height: 1.25rem;
}

.wh-1-875 {
  width: 1.875rem;
  height: 1.875rem;
}

.wh-0-68 {
  width: 0.68rem;
  height: 0.68rem;
}

.wh-0-68 {
  width: 0.68rem;
  height: 0.68rem;
}

.wh-1-21875 {
  width: 1.21875rem;
  height: 1.21875rem;
}

.wh-2-1875 {
  width: 2.1875rem;
  height: 2.1875rem;
}

.mw-21-25 {
  max-width: 21.25rem;
}
.mw-16-25 {
  max-width: 16.25rem;
}

.cg-0-625 {
  column-gap: 0.625rem;
}

.rg-0-75 {
  row-gap: 0.75rem;
}

.gap-1-25 {
  gap: 1.25rem;
}

.gap-2 {
  gap: 2rem;
}

.cg-2 {
  column-gap: 2rem;
}
.rg-3 {
  row-gap: 3rem;
}

.p-0-875 {
  padding: 0.875rem;
}

.p-3-5 {
  padding: 0.875rem;
}

.p-6-25 {
  padding: 6.25rem;
}

.px-1-25 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.pt-4-375 {
  padding-top: 4.375rem;
}

.pb-5-5275 {
  padding-bottom: 5.5275rem;
}

.py-0-625 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.py-1-25 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-3-71875 {
  padding-top: 3.71875rem;
  padding-bottom: 3.71875rem;
}

.py-1-5625rem {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
}

.mx-3-125 {
  margin-left: 3.125rem;
  margin-right: 3.125rem;
}

.pl-15 {
  padding-left: 3.75rem;
}

.pl-3-375 {
  padding-left: 3.375rem;
}

.pr-3-375 {
  padding-right: 3.375rem;
}

.mr-0-625 {
  margin-right: 0.625rem;
}

.mr-0-9375 {
  margin-right: 0.9375rem;
}

.px-0-625 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

@media (min-width: 768px) {
  .md\:mt-4-375 {
    margin-top: 4.375rem;
  }

  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:py-3-71875 {
    padding-top: 3.71875rem;
    padding-bottom: 3.71875rem;
  }

  .md\:p-3-75 {
    padding: 3.75rem;
  }

  .md\:p-6-25 {
    padding: 6.25rem;
  }
  .md\:py-6-25 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }

  .md\:px-5 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:py-1-5625 {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }

  .md\:h-5 {
    height: 5rem;
  }

  .md\:h-6-25 {
    height: 6.25rem;
  }

  .md\:h-6-5625 {
    height: 6.5625rem;
  }

  .md\:h-14-875rem {
    height: 14.875rem;
  }

  .md\:h-9-4825 {
    height: 9.4825rem;
  }

  .md\:w-3-5 {
    width: 3.5rem;
  }

  .md\:w-7-55 {
    width: 7.55rem;
  }

  .md\:w-11 {
    width: 11rem;
  }

  .md\:w-21-25 {
    width: 21.25rem;
  }

  .md\:w-41 {
    width: 41rem;
  }

  .md\:w-46-25 {
    width: 46.25rem;
  }

  .md\:w-16-25 {
    width: 16.25rem;
  }

  .md\:mw-15 {
    max-width: 15rem;
  }

  .md\:mw-16-25 {
    max-width: 16.25rem;
  }
  .md\:mw-20-75 {
    max-width: 20.75rem;
  }
  .md\:mw-21-25 {
    max-width: 21.25rem;
  }

  .md\:mw-24-75 {
    max-width: 24.75rem;
  }

  .md\:mw-28 {
    max-width: 28rem;
  }
  .md\:mw-30 {
    max-width: 30rem;
  }

  .md\:mw-56-5 {
    max-width: 56.5rem;
  }

  .md\:mw-60 {
    max-width: 60rem;
  }

  .md\:mh-65-5 {
    max-height: 65.5rem;
  }

  .md\:cg-9 {
    column-gap: 9rem;
  }
  .md\:rg-4-4 {
    row-gap: 4.4rem;
  }

  .md\:gap-1-25 {
    gap: 1.25rem;
  }

  .md\:gap-15 {
    gap: 3.75rem;
  }

  .md\:wh-2-5 {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:w-11 {
    width: 11rem;
  }
}
