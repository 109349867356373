.f-size-0-75 {
  font-size: 0.75rem;
  line-height: 0.75rem;
}

.f-size-0-875 {
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.f-size-2-625 {
  font-size: 2.625rem;
}

.f-size-1 {
  font-size: 1rem;
  line-height: 1rem;
}

.f-size-0-8125 {
  font-size: 0.8125rem;
}

.f-size-1-125 {
  font-size: 1.125rem;
  line-height: 1.125rem;
}

.f-size-1-25 {
  font-size: 1.25rem;
  line-height: 1.25rem;
}

.f-size-1-35 {
  font-size: 1.35rem;
  line-height: 1.35rem;
}

.f-size-1-5 {
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.f-size-2 {
  font-size: 2rem;
  line-height: 2rem;
}

.f-size-2-5 {
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.f-size-3-125 {
  font-size: 3.125rem;
  line-height: 3.125rem;
}

.f-size-3-375 {
  font-size: 3.375rem;
  line-height: 3.375rem;
}

.f-size-3-75 {
  font-size: 3.75rem;
  line-height: 3.75rem;
}

.f-size-4 {
  font-size: 4rem;
  line-height: 4rem;
}

.f-size-10-625 {
  font-size: 10.625rem;
}

.lh-1 {
  line-height: 1rem;
}

.lh-1-2 {
  line-height: 1.2rem;
}

.lh-1-3125 {
  line-height: 1.3125rem;
}

.lh-1-5 {
  line-height: 1.4rem;
}

.lh-1-75 {
  line-height: 1.75rem;
}

.lh-1-8 {
  line-height: 1.8rem;
}

.lh-1-575 {
  line-height: 1.575rem;
}

.lh-2-5875 {
  line-height: 2.5875rem;
}

.lh-2-7 {
  line-height: 2.7rem;
}

.lh-2-8 {
  line-height: 2.8rem;
}

.lh-5-25 {
  line-height: 5.25rem;
}

.color-violet-light {
  color: #584a8c;
}

.color-red {
  color: #a81b1b;
}

.color-red::placeholder {
  color: #a81b1b;
}

.color-green {
  color: #5c9673;
}

.color-gray {
  color: #7d7d7d;
}

.color-white {
  color: #fff;
}

.color-black {
  color: #1e1542;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 900;
}

.fw-900 {
  font-weight: 900;
}

.lt-m-0-01 {
  letter-spacing: -0.01rem;
}

.lt-0-01 {
  letter-spacing: 0.01rem;
}

.lt-0-02 {
  letter-spacing: 0.02rem;
}

.lt-0-03 {
  letter-spacing: 0.03rem;
}

.underline {
  text-decoration: underline;
}

.word-break {
  word-break: break-word;
}

@media (min-width: 768px) {
  .md\:f-size-1-125 {
    font-size: 1.125rem;
    line-height: 1.125rem;
  }

  .md\:f-size-1-25 {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  .md\:f-size-1-5 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  .md\:f-size-1-9 {
    font-size: 1.9rem;
    line-height: 1.9rem;
  }

  .md\:f-size-2 {
    font-size: 2rem;
    line-height: 2rem;
  }

  .md\:f-size-2-5 {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  .md\:f-size-3 {
    font-size: 3rem;
    line-height: 3rem;
  }

  .md\:f-size-3-125 {
    font-size: 3.125rem;
    line-height: 3.125rem;
  }

  .md\:f-size-3-75 {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }

  .md\:f-size-4 {
    font-size: 4rem;
    line-height: 4rem;
  }

  .md\:lt-0-01 {
    letter-spacing: 0.01rem;
  }

  .md\:lt-0-02 {
    letter-spacing: 0.02rem;
  }

  .md\:lt-0-03 {
    letter-spacing: 0.03rem;
  }

  .md\:lt-0-04 {
    letter-spacing: 0.04rem;
  }

  .md\:lt-0-08 {
    letter-spacing: 0.08rem;
  }

  .md\:lh-1-35 {
    line-height: 1.35rem;
  }

  .md\:lh-1-4 {
    line-height: 1.4rem;
  }

  .md\:lh-1-5 {
    line-height: 1.5rem;
  }

  .md\:lh-1-6 {
    line-height: 1.6rem;
  }

  .md\:lh-1-7 {
    line-height: 1.7rem;
  }

  .md\:lh-1-8 {
    line-height: 1.8rem;
  }

  .md\:f-size-3-375 {
    font-size: 3.375rem;
  }
}

@media (min-width: 1024px) {
  .lg\:f-size-10-625 {
    font-size: 10.625rem;
  }
}
