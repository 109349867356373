.bg-white-blue {
  background-color: #f9f8ff;
}

.bg-gray {
  background-color: #c8c8c8;
}

.bg-cream {
  background-color: #f1ede1;
}

.bg-cream-light {
  background-color: #faf7ed;
}

.bg-yellow {
  background-color: #ffa800;
}

.bg-dark-green {
  background-color: #69816b;
}

.bg-green {
  background-color: #5c9673;
}

.bg-violet-light {
  background-color: #f1eff9;
}

.bg-violet-dark {
  background-color: #1e1542;
}

.bc-green {
  border-color: #5c9673;
}

.bw-0-125 {
  border-width: 0.125rem;
}

.bc-red {
  border-color: #a81b1b;
}

.bc-violet {
  border-color: #584a8c;
}

.br-0-3125 {
  border-radius: 0.3125rem;
}

.br-0-9375 {
  border-radius: 0.9375rem;
}

.br-0-5 {
  border-radius: 0.5rem;
}

.br-0-625 {
  border-radius: 0.625rem;
}

.br-1-25 {
  border-radius: 1.25rem;
}

.br-t-1-25 {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

@media (min-width: 768px) {
  .md\:br-1-25 {
    border-radius: 1.25rem;
  }
  .md\:br-1 {
    border-radius: 1rem;
  }
  .md\:br-0-325 {
    border-radius: 0.325rem;
  }
  .md\:br-0-9375 {
    border-radius: 0.9375rem;
  }
}

/* @media (min-width: 0px) and (max-width: 640px) {
  .ssm\:hidden {
    display: none;
  }
} */

.right-1-25 {
  right: 1.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.bg-green-100 {
  background-color: #d1fae5; /* Light green background */
}

.bg-red-100 {
  background-color: #fee2e2; /* Light red background */
}

.lesson-button.current {
  background-color: #1fad0a;
  color: white;
}
